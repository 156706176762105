$(document).ready(function() {

  $('input[type=radio][name=paper-stock]').change(function() {
    alert('test');
  });

  // Custom radios
  $(document).on('click', '.custom-radio', function() {
    if (!$(this).hasClass('is-active')) {

      // Check if radio is in .custom-radio-has-tips wrapper
      if ($(this).parent().hasClass('custom-radio-has-tips')) {
        // If so, find parent's siblings' children, remove active class
        $(this).parent().siblings('.custom-radio-has-tips').find('.custom-radio').removeClass('is-active');
      } else {
        // If not, remove active class from direct siblings
        $(this).siblings('.custom-radio').removeClass('is-active');
      }
      var radio = $(this).find('input[type="radio"]');
      radio.prop('checked', true);
      $(this).addClass('is-active');


      // make sure we use the proper coating/stock
      if(radio.attr('name')=="stock_id"){
        $('#update-coatings').attr("value", "true");
        $('#product-coatings .custom-radio').removeClass('is-active');
      }

      // submit product option form
      var nearest_form = $(this).closest('form');
      if(nearest_form.attr('id') == 'product-options-form') {
        $('#product-options-submit').click();
      }
    }
  });

  // Product Quantity Change
  $('#product-quantites').change(function() {
    $('input[name=sku_id]').val('');
    $('#product-options-submit').click();
  });

  // Price grid squares
  $(document).on('click', '.price-grid-square', function() {
    var sku = $(this).data('sku-id');
    var price_type = $(this).data('production-speed');
    var quantity_id =  $(this).data('quantity-id');
    $('input[name=price_type]').val(price_type);
    $('input[name=sku_id]').val(sku);

    //update add start order / add to cart form
    $('#order_item_sku_id').val(sku);
    $('#order_item_price_type').val(price_type);

    $(this).closest('#product-order-form-grid').addClass('is-selected');
    $('.price-grid-square').removeClass('is-selected');
    $(this).addClass('is-selected');

    $.ajax({
      url: "/products/" + productId + "/pricing",
      type: "get",
      dataType: "script",
      success: closeFullGrid,
      data: {
        sku: sku,
        price_type: price_type,
        quantity_id: quantity_id
      }
    });

  });

  $(document).on('click', '#full-grid-btn', function() {
    $('input[name=show_full_grid]').val('1');
    $('#product-options-submit').click();
  });

  $(document).on('click', '#collapse-grid-btn', function() {
    $('input[name=show_full_grid]').val('0');
    $('#product-options-submit').click();
    $("#product-title").get(0).scrollIntoView({behavior: "smooth"});
  });

});

function closeFullGrid() {
  if ($('input[name=show_full_grid]').val() == '1'){
    $('input[name=show_full_grid]').val('0');
    $('#product-options-submit').click();
    $("#product-title").get(0).scrollIntoView({behavior: "smooth"});
  }
}
